<template>
  <WithQuery :base-filters="baseFilters">
    <div slot-scope="{ filters }">
      <ReportsListView
        view-title="Viewing All Reports"
        :items-per-page="15"
        :filters="filters"
        :query-params="queryParams"
        gets-search
      />
    </div>
  </WithQuery>
</template>

<script>
import * as appConfig from '@src/app.config'
import ReportsListView from '@components/ReportsListView/ReportsListView'
import WithQuery from '@components/DataProviders/WithQuery'

import { HumanFields, ReportStatuses } from '@constants/knack'

export default {
  name: 'AllReportsListView',
  components: { ReportsListView, WithQuery },
  metaInfo: {
    title: 'All Reports',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {}
  },
  computed: {
    baseFilters() {
      return {
        match: 'and',
        rules: [
          {
            field: HumanFields.DAILY_REPORT.REPORT_STATUS,
            operator: 'is not',
            value: ReportStatuses.LOCKED,
          },
          {
            field: HumanFields.DAILY_REPORT.LOCATION,
            operator: 'is not blank',
          },
        ],
      }
    }, // baseFilters
    queryParams() {
      return {
        sort_field: HumanFields.DAILY_REPORT.REPORT_DATE,
        sort_order: 'desc',
      }
    }, // queryParams
  },
  methods: {},
}
</script>
